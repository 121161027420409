<template>
  <div class="login-page">
    <div>
      <v-row class="d-flex justify-center" align="center">
        <!-- Connect and collaborate -->
        <v-col md="9" cols="11">
          <v-card class="ma-5" flat tile>
            <v-row class="h-full justify-center align-center">
              <v-col cols="12" md="5" lg="6" class="py-0">
                <v-img :src="require('@/assets/images/pages/person-client.png')" contain alt="Connect and socialize"
                  class="h-full" @load="loadImage"></v-img>
                <v-col cols="6" class="mx-auto text-center" v-show="!isImgLoaded">
                  <p>Loading image</p>
                  <v-progress-linear color="primary" indeterminate rounded height="6">
                  </v-progress-linear>
                </v-col>
              </v-col>
              <v-col cols="10" sm="10" md="6" lg="6" class="center">
                <div class="auth-inner mx-4 px-4">
                  <!-- title -->
                  <v-card-title class="d-flex align-center justify-center">
                    <h2 class="mx-auto font-weight-bolder">
                      Sign up here
                    </h2>
                  </v-card-title>
                  <v-card-subtitle class="d-flex align-center justify-center">
                    <h3 class="primary--text">(Dyulaba)</h3>
                  </v-card-subtitle>
                  <v-card-text class="d-flex align-center justify-center flex-wrap">
                    <span class="me-2 text-center">
                      Allready have an account?
                    </span>
                    <router-link :to="{ name: 'login' }">
                      Sign In
                    </router-link>
                  </v-card-text>

                  <!-- form input -->
                  <v-card-text class="mt-4">
                    <v-form ref="regform">
                      <v-text-field v-model="firstname" outlined dense label="First name" placeholder="Kana"
                        :error-messages="firstnameErrors.value" clearable :hide-details="firstnameErrors.isvalid"
                        type="text" @input="$v.firstname.$touch()" @blur="$v.firstname.$touch()" class="mb-2">
                      </v-text-field>

                      <v-text-field v-model="lastname" outlined dense label="Last name" type="text" placeholder="Emille"
                        :error-messages="lastnameErrors.value" clearable :hide-details="lastnameErrors.isvalid"
                        @input="$v.lastname.$touch()" @blur="$v.lastname.$touch()" class="mb-2"></v-text-field>

                      <v-text-field v-model="email" outlined dense :hide-details="emailErrors.isvalid"
                        label="Email adress" placeholder="john@example.com" type="email"
                        :error-messages="emailErrors.value" @input="$v.email.$touch()" @blur="$v.email.$touch()"
                        clearable class="mb-2">
                      </v-text-field>

                      <!-- <v-select v-model="countryItem" :items="countries" item-text="name" return-object dense outlined
                          label="Country" value="Cameroun" class="mb-0">
                        </v-select> -->

                      <!-- <v-select v-model="countryItem" :items="countries" item-text="name" return-object filled></v-select> -->

                      <!-- <v-text-field v-model="phonenumber" outlined dense type="number" label="Phone number"
                          placeholder="655 32 03 69" :hide-details="phonenumberErrors.isvalid"
                          :error-messages="phonenumberErrors.value" @input="$v.phonenumber.$touch()"
                          @blur="$v.phonenumber.$touch()" clearable class="mb-2">
                        </v-text-field> -->
                      <vue-phone-number-input v-model="phonenumber" @update="onUpdate" :default-country-code="'CM'"
                        class="mb-2 mt-0">
                      </vue-phone-number-input>

                      <v-text-field v-model="password" outlined dense :type="isPasswordVisible ? 'text' : 'password'"
                        label="Password" placeholder="············"
                        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                        @click:append="isPasswordVisible = !isPasswordVisible" :hide-details="passwordErrors.isvalid"
                        :error-messages="passwordErrors.value" @input="$v.password.$touch()"
                        @blur="$v.password.$touch()" clearable class="mb-2">
                      </v-text-field>

                      <v-text-field v-model="confpassword" outlined dense
                        :type="isConfPasswordVisible ? 'text' : 'password'" label="Confirm password"
                        placeholder="············"
                        :append-icon="isConfPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" clearable
                        @click:append="isConfPasswordVisible = !isConfPasswordVisible"
                        :hide-details="confirmPassErrors.isvalid" :error-messages="confirmPassErrors.value">
                      </v-text-field>
                      <div class="d-flex flex-column" v-if="errors">
                        <span class="error--text" v-for="(err, i) in errors" :key="i">{{ err }}</span>
                      </div>

                      <v-row>
                        <v-col class="d-flex justify-space-between">
                          <v-btn outlined color="error" class="mt-6 col-5" @click="resetValidation">
                            <!-- <v-btn outlined color="error" class="mt-6 col-5" @click="this.$alert('Hello Vue Simple Alert.')"> -->
                            Reset
                          </v-btn>
                          <v-btn color="primary" class="mt-6 col-5" :loading="loading" @click="submitform">
                            Save
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import SocializeBlockComponent from '@/layouts/components/SocializeBlockComponent.vue'

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { Drequest } from '@/plugins/Drequest'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    email: { required, email },
    password: { required, minLength: minLength(6), maxLength: maxLength(15) },
    // phonenumber: { required, minLength: minLength(8) },
    // select: { required },
    // checkbox: {
    //   checked(val) {
    //     return val
    //   },
    // },
  },
  components: {
    SocializeBlockComponent,
    VuePhoneNumberInput
  },
  data() {
    return {
      isImgLoaded: false,
      loading: false,
      errors: {},
      currentCountry: { name: 'CAMEROON', iso: 'CM', phonecode: '237', status: '0' },
      phoneCountry: {},
      // countries: [],
      // countryItem: {},
    }
  },
  computed: {
    // checkboxErrors() {
    //   const errors = []
    //   if (!this.$v.checkbox.$dirty) return errors
    //   !this.$v.checkbox.checked && errors.push('You must agree to continue!')
    //   return errors
    // },
    // selectErrors() {
    //   const errors = []
    //   if (!this.$v.select.$dirty) return errors
    //   !this.$v.select.required && errors.push('Item is required')
    //   return errors
    // },
    firstnameErrors() {
      const errors = { isvalid: true, value: [] }
      if (!this.$v.firstname.$dirty) return errors
      !this.$v.firstname.minLength && errors.value.push('First name must be at most 03 characters long') && (errors.isvalid = false)
      !this.$v.firstname.required && errors.value.push('Last name is required.') && (errors.isvalid = false)
      return errors
    },

    lastnameErrors() {
      const errors = { isvalid: true, value: [] }
      if (!this.$v.lastname.$dirty) return errors
      !this.$v.lastname.minLength && errors.value.push('Last name must be at most 03 characters long') && (errors.isvalid = false)
      !this.$v.lastname.required && errors.value.push('Last name is required.') && (errors.isvalid = false)
      return errors
    },

    emailErrors() {
      const errors = { isvalid: true, value: [] }
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.value.push('Must be valid e-mail') && (errors.isvalid = false)
      !this.$v.email.required && errors.value.push('E-mail is required') && (errors.isvalid = false)
      return errors
    },
    passwordErrors() {
      const errors = { isvalid: true, value: [] }
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength && errors.value.push('Password must be at most 6 characters long') && (errors.isvalid = false)
      !this.$v.password.required && errors.value.push('Password is required') && (errors.isvalid = false)
      return errors
    },
    confirmPassErrors() {
      const errors = { isvalid: true, value: [] }
      if (this.password == this.confpassword) {
        errors.isvalid = true
      } else {
        errors.value.push('Password must be the same')
        errors.isvalid = false
      }
      return errors
    },
    phonenumberErrors() {
      const errors = { isvalid: true, value: [] }
      if (!this.$v.phonenumber.$dirty) return errors
      !this.$v.phonenumber.minLength && errors.value.push('Phone number must be at most 8 characters long') && (errors.isvalid = false)
      !this.$v.phonenumber.required && errors.value.push('Password is required') && (errors.isvalid = false)
      return errors
    }
  },

  methods: {
    loadImage() {
      this.isImgLoaded = true;
    },
    onUpdate(payload) {
      this.phoneCountry = payload
      this.errors = { 'phone': '' }
    },
    checkValidationForm() {
      if (this.firstnameErrors.isvalid && this.lastnameErrors.isvalid && this.emailErrors.isvalid
        && this.phoneCountry.isValid
        && this.passwordErrors.isvalid && this.confirmPassErrors.isvalid) {
        return true
      }

    },
    resetValidation() {
      this.$v.$reset()
      this.firstname = ''
      this.lastname = ''
      this.email = ''
      // this.country = ''
      this.phonenumber = ''
      this.password = ''
      this.confpassword = '',
        this.errors = {}
    },
    submitform() {
      this.$v.$touch()
      // Drequest.api(`lazyloading.country?dfilters=on&iso:eq=${this.phoneCountry.countryCode}`)
      //   .get((response) => {
      //     if (response.success === true) {
      //       this.currentCountry = response.listEntity[0]
      //     }
      //   })
      //   .catch((err) => {
      //   })
      if (!this.phoneCountry.isValid) {
        this.errors = { 'phone': 'Incorrect phone number' }
      }
      var bodyFormData = {
        user: {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phonenumber: this.phonenumber,
          password: this.password,
          username: this.lastname,
          country_iso: this.phoneCountry.countryCode
        }
      }

      if (this.checkValidationForm()) {
        this.loading = true;
        // this.resetValidation()
        Drequest.api("user.create")
          .data(bodyFormData)
          .raw((response) => {
            if (response.success === true) {
              this.loading = false;
              this.$store.commit("setInitActivationCode", {
                init: true,
                id: response.user.id,
                email: response.user.email,
              });
              this.$store.commit("setUser", {
                id: response.user.id,
                email: response.user.email,
                firstname: response.user.firstname,
                lastname: response.user.lastname,
                phonenumber: response.user.phonenumber,
                username: response.user.username,
                isactivated: response.user.is_activated,
                birthdate: response.user.birthdate,
                adress: response.user.adress,
                city: response.user.city,
                lastlogin: response.user.last_login,
                logo: response.user.logo,
                activationcode: response.user.activationcode,
                api_key: response.user.api_key
              });

              this.$store.commit("setToken", response.user.activationcode);
              this.$store.commit("setApiToken", response.user.api_key);
              this.$fire({
                type: "success",
                text: "Please check your emails!\nWe have sent an activation code..",
              })
              this.$router.push({ name: 'activateaccount' })
            }
            else {
              this.errors = response.error
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
          })
      }
    },
  },

  setup() {
    const isPasswordVisible = ref(false)
    const isConfPasswordVisible = ref(false)
    const firstname = ref('')
    const lastname = ref('')
    const phonenumber = ref('')
    const email = ref('')
    // const country = ref('')
    const password = ref('')
    const confpassword = ref('')

    const icons = {
      mdiEyeOutline,
      mdiEyeOffOutline,
    }
    return {
      icons,
      isPasswordVisible, isConfPasswordVisible,
      // form variables
      firstname, lastname, phonenumber, email, password, confpassword,
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

//@override
.v-card {
  border-radius: 1em !important;
}

h1 {
  margin: 0 !important;
}

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}
</style>
